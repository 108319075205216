import React from "react";
import useTranslations from "src/utils/useTranslations";
import Logo from "src/components/logo";
import backgrounds from "src/components/backgrounds";
import randomNumber from "random-number-csprng";
import Axios from "axios";
import fileDownload from "js-file-download";
import { getNordVpnAdLink } from "src/utils/getNordVpnAdLink";
import BENCH_CLIENT from "src/utils/benchClient";
import { getCountry } from "src/utils/country";

const winFilename = "utweb_installer.exe";
const riseEndpoint = "https://d1aodu4gs77ogh.cloudfront.net";
const cherryLinkWin = `https://download-new.utorrent.com/endpoint/utweb/track/beta/os/riserollout?filename=${winFilename}`;

const heightParentHeightRatio = 0.45;
const nordVpnLink = getNordVpnAdLink();

class DownloadsComplete extends React.Component {

	constructor(props){
		super(props);
		this.state = {
			isChrome:false,
			isIE:false,
			fs:16
		};

		this.innerContainerRef = React.createRef();
	}

	adjustFs(prevFs){
		let height = this.innerContainerRef.current.clientHeight
		let parentHeight = this.innerContainerRef.current.offsetParent.clientHeight
		let fs = prevFs - .5
		if(fs <= 12) {
			return
		}
		if((height/parentHeight) > heightParentHeightRatio){
			this.setState({fs:fs})
		}
	}

	componentDidUpdate(prevProps, prevState){
		if(this.state.fs !== prevState.fs){
			this.adjustFs(this.state.fs)
		}
	}

	componentDidMount(){

		this.adjustFs(this.state.fs);

		let cherryDownloadLink = cherryLinkWin;

		if(window.gatsbyIsChrome){
			this.setState({
				isChrome: true,
				isIE: false
			});
		}
		else if(window.gatsbyIsIE){
			this.setState({
				isChrome: false,
				isIE: true
			});
		}
		const countryCode = getCountry();
		// file download action
		randomNumber(1, 100)
			.then(async (num) => {
				// brazil -> unwrapped
				// if (countryCode === "BR") {
				// 	throw new Error("use unwrapped build");
				// }
				// us 50% -> unwrapped
				// if (countryCode === "US" && num < 50) {
				// 	throw new Error("use unwrapped build");
				// }
				// 100% rise
				if (num <= 100) {
					// await-ing will result in request errors to throw, hence falling back to cherry
					await Axios.get(riseEndpoint, {
						responseType: "blob",
						params: {
							ic_user_id: "11093",
							c: "1",
							l: "URL",
							v: "2.1"
						}
					})
					.then(response =>  fileDownload(response.data, winFilename));
				} else {
					throw new Error("fallback to cherry");
				}
			})
			.catch(_ => {
				setTimeout(()=>{
					window.location = cherryDownloadLink;
				}, 100)
			});

		BENCH_CLIENT.ping('load.web_download');
	}

	render(){

		const { text, locale } = this.props.pageContext;
		const t = useTranslations(text)

		if (locale === "en" || locale === "ja" || locale === "zh_tw") {

			var runInstaller = <li className="my-3">{t("Run")} <span className='font-weight-bold'>{winFilename}</span> {t("to install")}</li>

			var tryAgain = <>
				<p className="text-danger font-weight-bold ml-3" style={{ ...font.md }}>
					* {t("if you have trouble downloading or installing, try this version.", { "version": <a href={cherryLinkWin} className="post-download-dark">{t(`version`)}</a> })}
				</p>
			</>
		}
		else{
			runInstaller = t(`Run µTorrentWebInstaller.pkg to install`)
			runInstaller = runInstaller.replace('µTorrentWebInstaller.pkg', `<span class='font-weight-bold'>${winFilename}</span>`)
			runInstaller = <li className="my-3" dangerouslySetInnerHTML={{__html: runInstaller}}></li>

			tryAgain = <>
				<p className="text-danger font-weight-bold ml-3" style={{ ...font.md }}>
					* {t("if you have trouble downloading or installing, try this version.", { "version": <a href={cherryLinkWin} className="post-download-dark">{t(`version`)}</a> })}
				</p>
				<br/>
			</>
		}

		return (
			<div className="download-complete-container top-section-container d-flex align-items-center flex-column background-noise-dark" style={backgrounds.darkBg}>
				<div className="container pt-0 px-5 mb-5 download-complete-inner-container" ref={this.innerContainerRef} style={{fontSize:`${this.state.fs}px`}}>
					<p className="text-white p-absolute text-center font-weight-bold" style={{fontSize:`${this.state.fs*2}px`, ...style.headerSpacing}}>{t("Thank you for downloading")}</p>
					<div className="row">
						<div className="col-lg-6 d-flex flex-column justify-content-center">
							<Logo productName="Web" secondaryColor="white" tag="p" fsMax={26} fsCoefficient={18} className="pdlp-logo text-left ml-3"/>
							<ul className="text-white m-0 download-complete-list text-left" style={font.md}>
								<li className="my-3">{t("Wait for the download to finish*")}</li>
								<li  className="my-3">{t("Exit µTorrent Web if application is running")}</li>
								{runInstaller}
								<li className="my-3">{t("µTorrent Web will run once installed")}</li>
							</ul>
							{tryAgain}
						</div>
					</div>
					<div className="row mt-4">
						<div className="col-12">
							<a target="_blank" href={nordVpnLink} style={nordStyle}>
							</a>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default DownloadsComplete

const nordStyle = {
	background: 'url(/images/nord-images/affiliate-features-campaign-1500x300.png)',
	backgroundSize: 'contain',
	backgroundRepeat: 'no-repeat',
	aspectRatio: '1500/300',
	width: '100%',
	display: 'block'
};

const font = {
	md:{
		fontSize:'1.25em',
	}
}

const style = {
	show:{
		display:'inline-block'
	},
	hide:{
		display:'none'
	},
	headerSpacing: {
		padding: '1em 0',
		margin: '1em 0'
	}
}
